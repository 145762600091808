.tr {
    text-align: left;
}

.table, .table td, .table th {
    border: 1px solid black;
    border-collapse: collapse;
}

.table td, .table th {
    padding: 3px
}